import React from "react";
import { createUseStyles, useTheme } from 'react-jss';
import PropTypes from "prop-types";
import classNames from "classnames";

import Accordion from './accordion';

const useStyles = createUseStyles(theme => ({
  root: {
    '& table': {
      width: '100%'
    },

    '& td': {
      fontSize: 12,
    },
    '& td:not(:first-child)': {
      textAlign: 'right'
    },
    '& th': {
      fontSize: 12,
      fontStyle: 'italic',
      textAlign: 'right'
    },
    '& tbody tr:nth-child(odd)': {
      backgroundColor: theme.colors.offWhite
    }
  },
  accordion: {
  }
}));

const Nutrition = ({ className, data }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  if (!data) {
    return null
  }

  return (
    <div className={classNames(className, classes.root)}>
      <Accordion className={classes.accordion} label="Näringsinnehåll">
        <table>
          <thead>
            <tr>
              <th colSpan={2}>per 100g</th>
              <th>st</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>kcal</td>
              <td>{data?.kcal?.toFixed(1) ?? '-'}</td>
              <td>{data?.kcal?.toFixed(1) ?? '-'}</td>
            </tr>
            <tr>
              <td>fett</td>
              <td>{data?.fat?.toFixed(1) ?? '-'}</td>
              <td>{data?.fat?.toFixed(1) ?? '-'}</td>
            </tr>
            <tr>
              <td>kolhydrater</td>
              <td>{data?.carbs?.toFixed(1) ?? '-'}</td>
              <td>{data?.carbs?.toFixed(1) ?? '-'}</td>
            </tr>
            <tr>
              <td>protein</td>
              <td>{data?.protein?.toFixed(1) ?? '-'}</td>
              <td>{data?.protein?.toFixed(1) ?? '-'}</td>
            </tr>
          </tbody>
        </table>
      </Accordion>
    </div>
  )
}

Nutrition.propTypes = {
  data: PropTypes.object
}

export default Nutrition

import React from "react"
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from "prop-types"
import { format, parseISO } from 'date-fns';

import Image from "./image";
import Ingredients from "./ingredients";
import Nutrition from "./nutrition";

const grid = {
  TEXT: 'text',
  INGREDIENTS: 'ingredients',
  RATINGS: 'rating',
  NUTRITION: 'nutrition'
}

const useStyles = createUseStyles(theme => ({
  cardItem: {
    display: 'flex',
    padding: '1rem',
    boxSizing: 'border-box',
    width: '100%',
    [theme.layout.responsive.twoColumnsQuery]: {
      width: '50%'
    }
  },/*
  [`@media (min-width: 56rem)`]: {
    cardItem: {
      width: '33.3333%'
    }
  },*/
  card: {
    borderRadius: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    width: '100%',
    boxShadow: [0, 20, 40, -14, '#00000080'],
    backgroundColor: theme.colors.white,
    //border: [1, 'solid', theme.colors.light],
  },
  image: {
    padding: [theme.layout.spacing.standard, theme.layout.spacing.standard, theme.layout.spacing.half, theme.layout.spacing.standard],
    maxHeight: '10rem'
  },
  imageInner: {
    height: '100%'
  },
  content: {
    padding: [theme.layout.spacing.half, theme.layout.spacing.standard, theme.layout.spacing.standard, theme.layout.spacing.standard],
    display: 'grid',
    height: '100%',
    gap: `${theme.layout.spacing.half} ${theme.layout.spacing.half}`,
    gridTemplateColumns: '165px 1fr',
    gridTemplateRows: '1fr 0fr 0fr',
    gridTemplateAreas: `
      "${grid.TEXT} ${grid.TEXT}"
      "${grid.RATINGS} ${grid.RATINGS}"
      "${grid.NUTRITION} ${grid.INGREDIENTS}"
    `,
    [theme.layout.responsive.twoColumnsQuery]: {
      gridTemplateColumns: '1fr 165px',
      gridTemplateRows: '0fr 1fr 0fr',
      gridTemplateAreas: `
        "${grid.TEXT} ${grid.INGREDIENTS}"
        "${grid.TEXT} ${grid.NUTRITION}"
        "${grid.RATINGS} ${grid.NUTRITION}"
      `,
    }
  },
  contentText: {
    gridArea: grid.TEXT,
  },/*
  contentTextLastUpdated: {
    fontSize: 12,
    fontStyle: 'italic',
    color: `${theme.colors.black}C0`
  },*/
  contentRatings: {
    gridArea: grid.RATINGS,
    display: 'flex'
  },
  contentIngredients: {
    gridArea: grid.INGREDIENTS
  },
  contentNutrition: {
    gridArea: grid.NUTRITION
  },
  contentRatingsColumn: {
    flexGrow: 1
  },
  seperator: {
    width: '75%',
    opacity: 0.5
  }
}));

const BurgerCard = ({ data }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <li className={classes.cardItem}>
      <div className={classes.card}>
        {data.productImage ? (
          <React.Fragment>
            <div className={classes.image}>
              <Image className={classes.imageInner} image={data.productImage} />
            </div>
            <hr className={classes.seperator} />
          </React.Fragment>
        ) : null}
        <div className={classes.content}>
          <div className={classes.contentText}>
            <h3>
              {data.name}
            </h3>
            <h4>
              {data.manufacturer}
            </h4>
            <p>
              {data.description}
            </p>
            {false &&
              <p className={classes.contentTextLastUpdated}>
                {`Senast uppdated ${format(parseISO(data.updated_at), 'yyyy-MM-dd')}`}
              </p>
            }
          </div>
          <Ingredients className={classes.contentIngredients} data={data.ingredients} />
          <Nutrition className={classes.contentNutrition} data={data.nutrition} />
          <div className={classes.contentRatings}>
            <div className={classes.contentRatingsColumn}>
              {data.pros.map(element => {
                return (
                  <div key={element.id}>
                    {element.text}
                  </div>
                );
              })}
            </div>
            <div className={classes.contentRatingsColumn}>
              {data.cons.map(element => {
                return (
                  <div key={element.id}>
                    {element.text}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

      </div>
    </li>
  )
}

BurgerCard.propTypes = {
  data: PropTypes.object
}

export default BurgerCard

import React from "react"
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from "prop-types"

import BurgerCard from "./burger-card";

const useStyles = createUseStyles(theme => ({
  cards: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: [theme.layout.spacing.large, 0],
    margin: 0
  }
}));

const BurgerCards = ({ data }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <ul className={classes.cards}>
      {data?.map(burger => {
        return (
          <BurgerCard data={burger} key={burger.id} />
        );
      })}
    </ul>
  )
}

BurgerCard.propTypes = {
  data: PropTypes.object
}

export default BurgerCards

import React, { useContext } from "react";
import { createUseStyles, useTheme } from 'react-jss';
import PropTypes from "prop-types";
import classNames from "classnames";

import EnumTranslationContext from './enum-translation-context';

const useStyles = createUseStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start'
  },
  ingredient: {
    flexGrow: 0,
    margin: 1,
    padding: [4, 12],
    backgroundColor: '#AfAfAf',
    borderRadius: 13
  }
}));

const Ingredients = ({ className, data }) => {
  const theme = useTheme();
  const classes = useStyles({ theme });
  const enumTranslationContext = useContext(EnumTranslationContext);

  if (!data) {
    return null
  }

  return (
    <div className={classNames(className, classes.root)}>
      {data.map(ingredient => {
        const translation = enumTranslationContext?.ingredientTranslations[ingredient.ingredient]

        if (!translation) {
          return null;
        }
        return (
          <span className={classes.ingredient} key={ingredient.id} style={{ backgroundColor: `${translation.color}80` }}>
            {translation.name}
          </span>
        );
      })}
    </div>
  )
}

Ingredients.propTypes = {
  data: PropTypes.array
}

export default Ingredients

import React from "react"
import { createUseStyles, useTheme } from 'react-jss'
import { useStaticQuery, graphql } from "gatsby"
import BurgerCards from "./burger-cards";
import EnumTranslationContext from './enum-translation-context';

const useStyles = createUseStyles(theme => ({
	rootBg: {
		backgroundColor: theme.colors.offWhite,
	},
	root: {
		...theme.layout.content
	}
}));


const MainContent = () => {
	const theme = useTheme();
	const classes = useStyles({ theme });
	const data = useStaticQuery(graphql`
		query AllBurgersQuery {
			allStrapiBurger(sort: {fields: id}, filter: {status: {eq: "published"}}) {
				nodes {
					id
					name
					manufacturer
					description
					productImage {
						childImageSharp {
							fluid(maxWidth: 350) {
								...GatsbyImageSharpFluid
							}
						}
					}
					weight
					nutrition {
						kcal
						carbs
						fat
						protein
					}
					ingredients {
						id,
						ingredient
					}
					cons {
						text
						id
					}
					pros {
						id
						text
					}
					updated_at
				}
			}
			strapiIngredientInfo {
				infos {
					color
					id
					name
					ingredient {
						ingredient
					}
				}
			}
		}
	`);

	const ingredientTranslations = React.useMemo(() => {
		return data.strapiIngredientInfo.infos.reduce((acc, current) => {
			acc[current.ingredient.ingredient] = {
				id: current.id,
				name: current.name,
				color: current.color
			}
			return acc;
		}, {})
	}, [data]);

	return (
		<EnumTranslationContext.Provider value={{ ingredientTranslations }}>
			<div className={classes.rootBg}>
				<div className={classes.root}>
					<BurgerCards data={data.allStrapiBurger?.nodes} />
				</div>
			</div>
		</EnumTranslationContext.Provider>
	)
}

export default MainContent

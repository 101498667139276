import React, { useState } from "react";
import { createUseStyles, useTheme } from 'react-jss';
import PropTypes from "prop-types";
import classNames from "classnames";
import { ChevronRight } from "react-feather";


const useStyles = createUseStyles(theme => ({
  button: {
    width: '100%',
    '&:hover, &:focus': {
      cursor: 'pointer'
    }
  },
  buttonIcon: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginRight: theme.layout.spacing.quarter,
    transition: 'transform 0.2s ease-out',
    '.isExpanded &': {
      transform: 'rotate(90deg)'
    }
  },
  content: {
    height: 'auto',
    maxHeight: 0,
    transition: 'max-height 0.2s ease-out',
    overflow: 'hidden',
    '.isExpanded &': {
      maxHeight: '200px'
    }
  }
}));

const Accordion = ({ children, className, label, defaultState, alwaysRender }) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(defaultState);

  return (
    <div className={classNames(className, { 'isExpanded': isExpanded })}>
      <button
        className={classNames(classes.button, 'reset-button')}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className={classes.buttonIcon}>
          <ChevronRight />
        </div>
        <span>
          {label}
        </span>
      </button>
      <div className={classes.content}>
        {(alwaysRender || isExpanded) &&
          <React.Fragment>
            {children}
          </React.Fragment>
        }
      </div>
    </div>
  );
}

Accordion.propTypes = {
  defaultState: PropTypes.bool.isRequired,
  alwaysRender: PropTypes.bool
}

Accordion.defaultProps = {
  defaultState: false,
  alwaysRender: true
}

export default Accordion
